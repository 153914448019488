import type { Area, Skill, Topic } from '../../definitions/skill-definitions';
import type {
  KnowledgeGraphAreaNode,
  KnowledgeGraphNode,
  KnowledgeGraphSkillNode,
  KnowledgeGraphTopicNode,
} from './knowledgeGraphDefinitions';
import knowledgeGraphEdges from './knowledgeGraphEdges';

const knowledgeGraphNodes: (
  | KnowledgeGraphSkillNode
  | KnowledgeGraphTopicNode
  | KnowledgeGraphAreaNode
)[] = (
  [
    {
      id: 1,
      type: 'area',
      textId: 'pitch_and_harmony',
      title: 'Pitch and Harmony',
    },
    {
      id: 2,
      type: 'area',
      textId: 'rhythm',
      title: 'Rhythm',
    },
    {
      id: 3,
      type: 'area',
      textId: 'ear_training',
      title: 'Ear Training',
    },
    {
      id: 1,
      type: 'topic',
      textId: 'piano',
      title: 'Piano',
    },
    {
      id: 2,
      type: 'topic',
      textId: 'treble_clef',
      title: 'Treble Clef',
    },
    {
      id: 3,
      type: 'topic',
      textId: 'accidentals',
      title: 'Accidentals',
    },
    {
      id: 4,
      type: 'topic',
      textId: 'bass_clef',
      title: 'Bass Clef',
    },
    {
      id: 5,
      type: 'topic',
      textId: 'half_and_whole_steps',
      title: 'Half & Whole Steps',
    },
    {
      id: 6,
      type: 'topic',
      textId: 'major_scales',
      title: 'Major Scales',
    },
    {
      id: 7,
      type: 'topic',
      textId: 'major_key_signatures',
      title: 'Major Key Signatures',
    },
    {
      id: 8,
      type: 'topic',
      textId: 'minor_scales',
      title: 'Minor Scales',
    },
    {
      id: 9,
      type: 'topic',
      textId: 'minor_key_signatures',
      title: 'Minor Key Signatures',
    },
    {
      id: 10,
      type: 'topic',
      textId: 'scale_degrees',
      title: 'Scale Degrees',
    },
    {
      id: 11,
      type: 'topic',
      textId: 'intervals',
      title: 'Intervals',
    },
    {
      id: 12,
      type: 'topic',
      textId: 'triads',
      title: 'Triads',
    },
    {
      id: 13,
      type: 'topic',
      textId: 'roman_numerals',
      title: 'Roman Numerals',
    },
    {
      id: 14,
      type: 'topic',
      textId: 'seventh_chords',
      title: 'Seventh Chords',
    },
    {
      id: 15,
      type: 'topic',
      textId: 'c_clefs',
      title: 'C Clefs',
    },

    // Rhythm Topics:
    {
      id: 1000,
      type: 'topic',
      textId: 'rhythm_math',
      title: 'Rhythm Math',
      tempo: [0, 0],
    },
    {
      id: 1001,
      type: 'topic',
      textId: 'simple_first_subdivision',
      title: 'Simple Time: Beat & subdivision',
      tempo: [60, 104],
    },
    {
      id: 1002,
      type: 'topic',
      textId: 'compound_beat_and_subdivision',
      title: 'Compound Time: Beat & subdivision',
      tempo: [46, 94],
    },
    {
      id: 1003,
      type: 'topic',
      textId: 'simple_offbeats_and_dotted',
      title: 'Simple Time: Offbeats and Dots',
      tempo: [60, 104],
    },
    {
      id: 1004,
      type: 'topic',
      textId: 'compound_patterns_first_subdivision',
      title: 'Compound Time: Patterns & offbeats',
      tempo: [46, 90],
    },
    {
      id: 1005,
      type: 'topic',
      textId: 'cut_time_basic',
      title: 'Cut Time: Basic Patterns',
      tempo: [60, 100],
    },
    {
      id: 1006,
      type: 'topic',
      textId: 'simple_eighth_syncopations',
      title: 'Simple Time: Syncopations 1',
      tempo: [60, 104],
    },
    {
      id: 1007,
      type: 'topic',
      textId: 'simple_sixteenth_groups',
      title: 'Simple Time: Sixteenth Notes',
      tempo: [50, 80],
    },
    {
      id: 1008,
      type: 'topic',
      textId: 'compound_sixteenth_groups',
      title: 'Compound Time: Sixteenth Notes',
      tempo: [40, 60],
    },
    {
      id: 1009,
      type: 'topic',
      textId: 'cut_time_syncopations',
      title: 'Cut Time: Syncopations',
      tempo: [60, 100],
    },
    {
      id: 1010,
      type: 'topic',
      textId: 'simple_sixteenth_syncopations',
      title: 'Simple Time: Syncopations 2',
      tempo: [40, 80],
    },
    {
      id: 1011,
      type: 'topic',
      textId: 'compound_dotted_eighths',
      title: 'Compound Time: Dotted Eighths',
      tempo: [40, 60],
    },
    {
      id: 1012,
      type: 'topic',
      textId: 'compound_sixteenth_syncopations',
      title: 'Compound Time: Syncopations 2',
      tempo: [40, 60],
    },

    // Ear Training Topics:
    {
      id: 2000,
      type: 'topic',
      textId: 'melodic_contour',
      title: 'Melodic Contour',
    },
    {
      id: 2010,
      type: 'topic',
      textId: 'conjunct_disjunct',
      title: 'Conjunct/Disjunct',
    },

    {
      id: 2025,
      type: 'topic',
      textId: 'solfege_et',
      title: 'Solfege Systems',
    },
    {
      id: 2030,
      type: 'topic',
      textId: 'scale_degree_et',
      title: 'One Note Dictation',
    },
    {
      id: 2040,
      type: 'topic',
      textId: 'muti_note_degree_et',
      title: 'Melodic Fragment Dictation',
    },
    {
      id: 2050,
      type: 'topic',
      textId: 'melodic_intervals',
      title: 'Melodic Intervals',
    },
    {
      id: 2060,
      type: 'topic',
      textId: 'harmonic_intervals',
      title: 'Harmonic Intervals',
    },
    {
      id: 2070,
      type: 'topic',
      textId: 'triads_et',
      title: 'Triads Ear Training',
    },
    {
      id: 2080,
      type: 'topic',
      textId: 'seventh_chord_et',
      title: 'Seventh Chords',
    },
    // {
    //   id: 2090,
    //   type: 'topic',
    //   textId: 'melodic_fragments',
    //   title: 'Melodic Fragments',
    // },
    // {
    //   id: 2100,
    //   type: 'topic',
    //   textId: 'melodic_dictation',
    //   title: 'Melodic Dictation',
    // },
    // Pitch and Harmony Skills:
    {
      id: 1,
      type: 'skill',
      textId: 'piano_layout',
      title: 'Pattern of white and black notes',
      master: {
        accuracy: 100,
        speed: 10,
      },
      advance: {
        accuracy: 0,
        speed: 60,
      },
      minQuestions: 3,
    },
    {
      id: 2,
      type: 'skill',
      textId: 'piano_c_g',
      title: 'Finding C-G',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 17,
    },
    {
      id: 3,
      type: 'skill',
      textId: 'piano_a_b',
      title: 'Finding A & B',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 6,
    },
    {
      id: 4,
      type: 'skill',
      textId: 'lines_spaces',
      title: 'Lines and Spaces on Staff',
      master: {
        accuracy: 80,
        speed: 10,
      },
      advance: {
        accuracy: 0,
        speed: 0,
      },
      minQuestions: 7,
    },
    {
      id: 5,
      type: 'skill',
      textId: 'treble_c_g',
      title: 'Treble C4-G4',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 15,
    },
    {
      id: 6,
      type: 'skill',
      textId: 'treble_a_g',
      title: 'Treble A4-G5',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 15,
    },
    {
      id: 7,
      type: 'skill',
      textId: 'treble_low_legers',
      title: 'Low Legers G3-B3',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 10,
    },
    {
      id: 8,
      type: 'skill',
      textId: 'treble_high_legers',
      title: 'High Legers A5 - D6',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 10,
    },
    {
      id: 85,
      type: 'skill',
      textId: 'treble_clef_octave_numbers',
      title: 'Treble Clef Octave Numbers',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 5,
    },
    {
      id: 9,
      type: 'skill',
      textId: 'accidental_types',
      title: 'Accidental Types',
      master: {
        accuracy: 95,
        speed: undefined,
      },
      advance: {
        accuracy: 80,
        speed: undefined,
      },
      minQuestions: 5,
    },
    {
      id: 10,
      type: 'skill',
      textId: 'sharps',
      title: 'Sharps on Keyboard',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 7,
    },
    {
      id: 11,
      type: 'skill',
      textId: 'flats',
      title: 'Flats on Keyboard',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 7,
    },
    {
      id: 11.5,
      type: 'skill',
      textId: 'naturals',
      title: 'Naturals',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 0,
    },
    {
      id: 12,
      type: 'skill',
      textId: 'double_sharps',
      title: 'Double Sharps on Keyboard',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 7,
    },
    {
      id: 13,
      type: 'skill',
      textId: 'double_flats',
      title: 'Double Flats on Keyboard',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 7,
    },
    {
      id: 18,
      type: 'skill',
      textId: 'enharmonic_equivalence',
      title: 'Enharmonic Equivalence',
      master: {
        accuracy: 90,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 20,
      },
      minQuestions: 5,
    },
    {
      id: 14,
      type: 'skill',
      textId: 'bass_lines',
      title: 'Bass Clef Lines',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 10,
    },
    {
      id: 15,
      type: 'skill',
      textId: 'bass_spaces',
      title: 'Bass Clef Spaces',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 10,
    },
    {
      id: 16,
      type: 'skill',
      textId: 'bass_high_legers',
      title: 'Legers B3-E4',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 10,
    },
    {
      id: 17,
      type: 'skill',
      textId: 'bass_low_legers',
      title: 'Legers F2-C1',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 10,
    },
    {
      id: 86,
      type: 'skill',
      textId: 'bass_clef_octave_numbers',
      title: 'Bass Clef Octave Numbers',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 5,
    },
    {
      id: 19,
      type: 'skill',
      textId: 'half_steps_piano',
      title: 'Half steps on kbd',
      master: {
        accuracy: 90,
        speed: 8,
      },
      advance: {
        accuracy: 70,
        speed: 15,
      },
      minQuestions: 10,
    },
    {
      id: 20,
      type: 'skill',
      textId: 'whole_steps_piano',
      title: 'Whole steps on kbd',
      master: {
        accuracy: 90,
        speed: 8,
      },
      advance: {
        accuracy: 70,
        speed: 15,
      },
      minQuestions: 10,
    },
    {
      id: 21,
      type: 'skill',
      textId: 'chs',
      title: 'CHS on staff',
      master: {
        accuracy: 90,
        speed: 12,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 10,
    },
    {
      id: 22,
      type: 'skill',
      textId: 'dhs',
      title: 'DHS on staff',
      master: {
        accuracy: 90,
        speed: 12,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 10,
    },
    {
      id: 23,
      type: 'skill',
      textId: 'ws',
      title: 'WS on staff',
      master: {
        accuracy: 90,
        speed: 12,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 10,
    },
    {
      id: 24,
      type: 'skill',
      textId: 'major_scales_piano',
      title: 'Major scales on kbd',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 10,
    },
    {
      id: 25,
      type: 'skill',
      textId: 'major_scales_staff',
      title: 'Major scales on staff',
      master: {
        accuracy: 90,
        speed: 30,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 10,
    },
    {
      id: 26,
      type: 'skill',
      textId: 'note_in_key',
      title: 'Key Sig: is note in key?',
      master: {
        accuracy: 95,
        speed: 4,
      },
      advance: {
        accuracy: 70,
        speed: 10,
      },
      minQuestions: 7,
    },
    {
      id: 27,
      type: 'skill',
      textId: 'order_sharps',
      title: 'Order sharps',
      master: {
        accuracy: 95,
        speed: 12,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 3,
    },
    {
      id: 28,
      type: 'skill',
      textId: 'treble_order_sharps',
      title: 'Treble sharps pattern',
      master: {
        accuracy: 95,
        speed: 20,
      },
      advance: {
        accuracy: 70,
        speed: 40,
      },
      minQuestions: 3,
    },
    {
      id: 29,
      type: 'skill',
      textId: 'bass_order_sharps',
      title: 'Bass sharps pattern',
      master: {
        accuracy: 95,
        speed: 20,
      },
      advance: {
        accuracy: 70,
        speed: 40,
      },
      minQuestions: 3,
    },
    {
      id: 30,
      type: 'skill',
      textId: 'sharp_majors',
      title: 'Sharp Major Keys',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 25,
      },
      minQuestions: 15,
    },
    {
      id: 31,
      type: 'skill',
      textId: 'order_flats',
      title: 'Order of Flats',
      master: {
        accuracy: 95,
        speed: 12,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 3,
    },
    {
      id: 32,
      type: 'skill',
      textId: 'treble_order_flats',
      title: 'Treble flats pattern',
      master: {
        accuracy: 95,
        speed: 20,
      },
      advance: {
        accuracy: 70,
        speed: 40,
      },
      minQuestions: 3,
    },
    {
      id: 33,
      type: 'skill',
      textId: 'bass_order_flats',
      title: 'Bass flats pattern',
      master: {
        accuracy: 95,
        speed: 20,
      },
      advance: {
        accuracy: 70,
        speed: 40,
      },
      minQuestions: 3,
    },
    {
      id: 34,
      type: 'skill',
      textId: 'flat_majors',
      title: 'Flat Major Keys',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 25,
      },
      minQuestions: 15,
    },
    {
      id: 35,
      type: 'skill',
      textId: 'c_major',
      title: 'C Major',
      master: {
        accuracy: 95,
        speed: 5,
      },
      advance: {
        accuracy: 70,
        speed: 25,
      },
      minQuestions: 1,
    },
    {
      id: 36,
      type: 'skill',
      textId: 'circle_of_fifths',
      title: 'Circle of Fifths',
      master: {
        accuracy: 95,
        speed: 150,
      },
      advance: {
        accuracy: 70,
        speed: 800,
      },
      minQuestions: 8,
    },
    {
      id: 55,
      type: 'skill',
      textId: 'imaginary_keys',
      title: 'Imaginary Key Sigs',
      master: {
        accuracy: 90,
        speed: 17,
      },
      advance: {
        accuracy: 70,
        speed: 45,
      },
      minQuestions: 6,
    },
    {
      id: 37,
      type: 'skill',
      textId: 'natural_minor_piano',
      title: 'Nat Minor on Kbd',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 5,
    },
    {
      id: 38,
      type: 'skill',
      textId: 'natural_minor',
      title: 'Nat Minor on Staff',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 7,
    },
    {
      id: 45,
      type: 'skill',
      textId: 'harmonic_minor',
      title: 'Harmonic Minor on Staff',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 7,
    },
    {
      id: 46,
      type: 'skill',
      textId: 'harmonic_minor_piano',
      title: 'Harmonic Minor on Piano',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 5,
    },
    {
      id: 47,
      type: 'skill',
      textId: 'melodic_minor',
      title: 'Melodic Minor on Staff',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 7,
    },
    {
      id: 48,
      type: 'skill',
      textId: 'melodic_minor_piano',
      title: 'Melodic Minor on Piano',
      master: {
        accuracy: 90,
        speed: 25,
      },
      advance: {
        accuracy: 70,
        speed: 75,
      },
      minQuestions: 5,
    },
    {
      id: 39,
      type: 'skill',
      textId: 'a_minor',
      title: 'A Minor Key Sig',
      master: {
        accuracy: 95,
        speed: 5,
      },
      advance: {
        accuracy: 70,
        speed: 25,
      },
      minQuestions: 2,
    },
    {
      id: 40,
      type: 'skill',
      textId: 'sharp_minors',
      title: 'Sharp Minor Keys',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 40,
      },
      minQuestions: 15,
    },
    {
      id: 41,
      type: 'skill',
      textId: 'flat_minors',
      title: 'Flat Minor Keys',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 40,
      },
      minQuestions: 15,
    },
    {
      id: 42,
      type: 'skill',
      textId: 'parallel_keys',
      title: 'Parallel Keys',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 50,
      },
      minQuestions: 8,
    },
    {
      id: 43,
      type: 'skill',
      textId: 'relative_keys',
      title: 'Relative Keys',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 50,
      },
      minQuestions: 8,
    },
    {
      id: 44,
      type: 'skill',
      textId: 'scale_degree_names',
      title: 'Scale Degree Names',
      master: {
        accuracy: 80,
        speed: undefined,
      },
      advance: {
        accuracy: 50,
        speed: undefined,
      },
      minQuestions: 8,
    },
    {
      id: 441,
      type: 'skill',
      textId: 'scale_degree_conversion_major',
      title: 'Major Scale Degrees ID',
      master: {
        accuracy: 95,
        speed: 8,
      },
      advance: {
        accuracy: 50,
        speed: 25,
      },
      minQuestions: 8,
    },
    {
      id: 442,
      type: 'skill',
      textId: 'scale_degree_conversion_minor',
      title: 'Minor Scale Degrees ID',
      master: {
        accuracy: 80,
        speed: 8,
      },
      advance: {
        accuracy: 50,
        speed: 25,
      },
      minQuestions: 8,
    },
    {
      id: 49,
      type: 'skill',
      textId: 'generic_intervals',
      title: 'Generic Intervals',
      master: {
        accuracy: 95,
        speed: 5,
      },
      advance: {
        accuracy: 70,
        speed: 25,
      },
      minQuestions: 15,
    },
    {
      id: 495,
      type: 'skill',
      textId: 'interval_groups',
      title: 'Perfect vs. Major/minor Groups',
      master: {
        accuracy: 95,
        speed: 5,
      },
      advance: {
        accuracy: 70,
        speed: 15,
      },
      minQuestions: 12,
    },
    {
      id: 50,
      type: 'skill',
      textId: 'simple_and_compound_intervals',
      title: 'Simple/Compound Intervals',
      master: {
        accuracy: 95,
        speed: 5,
      },
      advance: {
        accuracy: 70,
        speed: 20,
      },
      minQuestions: 12,
    },
    {
      id: 51,
      type: 'skill',
      textId: 'ascending_major_perfect',
      title: 'Asc Majors/Perfects',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 45,
      },
      minQuestions: 12,
    },
    {
      id: 52,
      type: 'skill',
      textId: 'ascending_others',
      title: 'Asc Others',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 50,
      },
      minQuestions: 12,
    },
    {
      id: 53,
      type: 'skill',
      textId: 'descending_minor_perfect',
      title: 'Dsc Minors/Perfects',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 45,
      },
      minQuestions: 12,
    },
    {
      id: 54,
      type: 'skill',
      textId: 'descending_others',
      title: 'Dsc Others',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 50,
      },
      minQuestions: 12,
    },
    {
      id: 60,
      type: 'skill',
      textId: 'interval_inversion',
      title: 'Interval Inversion',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 8,
    },
    {
      id: 545,
      type: 'skill',
      textId: 'enharmonic_intervals',
      title: 'Enharmonic Intervals',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 30,
      },
      minQuestions: 8,
    },
    {
      id: 555,
      type: 'skill',
      textId: 'spelling_triads',
      title: 'Spelling Triads',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 56,
      type: 'skill',
      textId: 'major_triads',
      title: 'Major Triad',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 57,
      type: 'skill',
      textId: 'minor_triads',
      title: 'Minor Triad',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 58,
      type: 'skill',
      textId: 'augmented_triads',
      title: 'Aug Triad',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 59,
      type: 'skill',
      textId: 'diminished_triads',
      title: 'Dim Triad',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 61,
      type: 'skill',
      textId: 'triad_spacing',
      title: 'Triad Spacing',
      master: {
        accuracy: 95,
        speed: 30,
      },
      advance: {
        accuracy: 70,
        speed: 50,
      },
      minQuestions: 6,
    },
    {
      id: 68,
      type: 'skill',
      textId: 'triad_inversion',
      title: 'Triad inversion',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 69,
      type: 'skill',
      textId: 'triad_figured_bass',
      title: 'Triad Figured Bass',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 600,
      type: 'skill',
      textId: 'triad_rn_qualities_major',
      title: 'Triad RN qualities in Major',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 4,
    },
    {
      id: 601,
      type: 'skill',
      textId: 'triad_rn_qualities_minor',
      title: 'Triad RN qualities in minor',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 4,
    },
    {
      id: 602,
      type: 'skill',
      textId: 'seventh_rn_qualities_major',
      title: 'Seventh chord RN qualities in Major',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 4,
    },
    {
      id: 603,
      type: 'skill',
      textId: 'seventh_rn_qualities_minor',
      title: 'Seventh chord RN qualities in minor',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 4,
    },
    {
      id: 604,
      type: 'skill',
      textId: 'triad_rn_major',
      title: 'Triad RNs in Major',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 605,
      type: 'skill',
      textId: 'triad_rn_minor',
      title: 'Triad RNs in minor',
      master: {
        accuracy: 95,
        speed: 10,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 606,
      type: 'skill',
      textId: 'seventh_rn_major',
      title: 'Seventh Chord RNs in Major',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 607,
      type: 'skill',
      textId: 'seventh_rn_minor',
      title: 'Seventh Chord RNs in minor',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 608,
      type: 'skill',
      textId: 'raise_the_leading_tone',
      title: 'Raising Leading Tone in minor',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 609,
      type: 'skill',
      textId: 'rn_open_spacing',
      title: 'Open Spacing RN',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 610,
      type: 'skill',
      textId: 'rn_close_spacing',
      title: 'Close Spacing RN',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 1,
    },
    {
      id: 611,
      type: 'skill',
      textId: 'rn_inversion',
      title: 'Roman Numeral Inversion',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 78,
      type: 'skill',
      textId: 'seventh_chord_spelling',
      title: 'Seventh Chord Spelling',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 79,
      type: 'skill',
      textId: 'seventh_chord_types',
      title: 'Seventh Chord Types',
      master: {
        accuracy: 95,
        speed: 60,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 1,
    },
    {
      id: 70,
      type: 'skill',
      textId: 'major_seventh_chords',
      title: 'Major Seventh Chords',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 71,
      type: 'skill',
      textId: 'minor_seventh_chords',
      title: 'Minor Seventh Chords',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 72,
      type: 'skill',
      textId: 'dominant_seventh_chords',
      title: 'Dominant Seventh Chords',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 73,
      type: 'skill',
      textId: 'diminished_seventh_chords',
      title: 'Diminished Seventh Chords',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 74,
      type: 'skill',
      textId: 'half_diminished_seventh_chords',
      title: 'Half-Diminished Seventh Chords',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 5,
    },
    {
      id: 75,
      type: 'skill',
      textId: 'seventh_chord_spacing',
      title: 'Seventh Chord Spacing',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 76,
      type: 'skill',
      textId: 'seventh_chord_inversion',
      title: 'Seventh Chord Inversion',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 77,
      type: 'skill',
      textId: 'seventh_chord_figured_bass',
      title: 'Seventh Chord Figured Bass',
      master: {
        accuracy: 95,
        speed: 15,
      },
      advance: {
        accuracy: 70,
        speed: 60,
      },
      minQuestions: 6,
    },
    {
      id: 80,
      type: 'skill',
      textId: 'alto_clef',
      title: 'Alto Clef',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 8,
      },
      minQuestions: 20,
    },
    {
      id: 81,
      type: 'skill',
      textId: 'tenor_clef',
      title: 'Tenor Clef',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 8,
      },
      minQuestions: 20,
    },
    {
      id: 82,
      type: 'skill',
      textId: 'soprano_clef',
      title: 'Soprano Clef',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 8,
      },
      minQuestions: 20,
    },
    {
      id: 83,
      type: 'skill',
      textId: 'mezzo_soprano_clef',
      title: 'Mezzo-Soprano Clef',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 8,
      },
      minQuestions: 20,
    },
    {
      id: 84,
      type: 'skill',
      textId: 'baritone_clef',
      title: 'Baritone Clef',
      master: {
        accuracy: 95,
        speed: 2,
      },
      advance: {
        accuracy: 70,
        speed: 8,
      },
      minQuestions: 20,
    },
    // Ear Training Skills:
    {
      id: 2000,
      type: 'skill',
      title: 'Ascending/Descending',
      textId: 'contour_basic',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2001,
      type: 'skill',
      title: '2-pitch level',
      textId: 'contour_high_low',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2002,
      type: 'skill',
      title: '3-pitch easy',
      textId: 'contour_high_medium_low_easy',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2003,
      type: 'skill',
      title: '3-pitch hard',
      textId: 'contour_high_medium_low_hard',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2004,
      type: 'skill',
      title: '4-pitch easy',
      textId: 'contour_four_level_easy',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2005,
      type: 'skill',
      title: '4-pitch hard',
      textId: 'contour_four_level_hard',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2010,
      type: 'skill',
      title: 'Basic',
      textId: 'conjunct_disjunct_basic',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2011,
      type: 'skill',
      title: 'Direction changes',
      textId: 'conjunct_disjunct_direction_changes',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2012,
      type: 'skill',
      title: 'Steps vs thirds',
      textId: 'conjunct_disjunct_steps_vs_thirds',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 2013,
      type: 'skill',
      title: 'Two notes',
      textId: 'conjunct_disjunct_2_note',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 8,
    },
    {
      id: 202501,
      type: 'skill',
      title: 'Major Scale Solfege',
      textId: 'major_scale_solfege',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 15,
    },
    {
      id: 202502,
      type: 'skill',
      title: 'Major Scale Hand Signs',
      textId: 'major_scale_hand_signs',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 15,
    },
    {
      id: 202510,
      type: 'skill',
      title: {
        scaleDegrees: 'Identify tonic (C Major)',
        solfegeDoMinor: 'Identify Do (C Major)',
        solfegeLaMinor: 'Identify Do (C Major)',
      },
      textId: 'identify_tonic_c_major',
      master: { accuracy: 95, speed: 13 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 2,
    },
    {
      id: 202511,
      type: 'skill',
      title: {
        scaleDegrees: 'Identify tonic (sharp Major keys)',
        solfegeDoMinor: 'Identify Do (sharp Major keys)',
        solfegeLaMinor: 'Identify Do (sharp Major keys)',
      },
      textId: 'identify_tonic_major_sharp_keys',
      master: { accuracy: 95, speed: 13 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 202512,
      type: 'skill',
      title: {
        scaleDegrees: 'Identify tonic (flat Major keys)',
        solfegeDoMinor: 'Identify Do (flat Major keys)',
        solfegeLaMinor: 'Identify Do (flat Major keys)',
      },
      textId: 'identify_tonic_major_flat_keys',
      master: { accuracy: 95, speed: 13 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 202513,
      type: 'skill',
      title: {
        scaleDegrees: 'Label scale degrees (Major)',
        solfegeDoMinor: 'Label solfege (Major)',
        solfegeLaMinor: 'Label solfege (Major)',
      },
      textId: 'labeling_solfege_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 10,
    },
    {
      id: 202514,
      type: 'skill',
      title: {
        scaleDegrees: 'Write scale degrees on the staff (Major)',
        solfegeDoMinor: 'Write solfege on the staff (Major)',
        solfegeLaMinor: 'Write solfege on the staff (Major)',
      },
      textId: 'writing_solfege_major',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 10,
    },
    {
      id: 202503,
      type: 'skill',
      title: 'Natural Minor Scale Solfege',
      textId: 'natural_minor_scale_solfege',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 10,
    },
    {
      id: 202504,
      type: 'skill',
      title: 'Natural Minor Scale Hand Signs',
      textId: 'natural_minor_scale_hand_signs',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 5,
    },
    {
      id: 202515,
      type: 'skill',
      title: {
        scaleDegrees: 'Identify tonic (A minor)',
        solfegeDoMinor: 'Identify Do (A minor)',
        solfegeLaMinor: 'Identify La (A minor)',
      },
      textId: 'identify_tonic_a_minor',
      master: { accuracy: 95, speed: 16 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 2,
    },
    {
      id: 202516,
      type: 'skill',
      title: {
        scaleDegrees: 'Identify tonic (sharp minor keys)',
        solfegeDoMinor: 'Identify Do (sharp minor keys)',
        solfegeLaMinor: 'Identify La (sharp minor keys)',
      },
      textId: 'identify_tonic_minor_sharp_keys',
      master: { accuracy: 95, speed: 16 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 202517,
      type: 'skill',
      title: {
        scaleDegrees: 'Identify tonic (flat minor keys)',
        solfegeDoMinor: 'Identify Do (flat minor keys)',
        solfegeLaMinor: 'Identify La (flat minor keys)',
      },
      textId: 'identify_tonic_minor_flat_keys',
      master: { accuracy: 95, speed: 16 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 202518,
      type: 'skill',
      title: {
        scaleDegrees: 'Label scale degrees (natural minor)',
        solfegeDoMinor: 'Label solfege (natural minor)',
        solfegeLaMinor: 'Label solfege (natural minor)',
      },
      textId: 'labeling_solfege_natural_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 10,
    },
    {
      id: 202519,
      type: 'skill',
      title: {
        scaleDegrees: 'Write scale degrees on the staff (natural minor)',
        solfegeDoMinor: 'Write solfege on the staff (natural minor)',
        solfegeLaMinor: 'Write solfege on the staff (natural minor)',
      },
      textId: 'writing_solfege_natural_minor',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 10,
    },
    {
      id: 202505,
      type: 'skill',
      title: 'Harmonic Minor Scale Solfege',
      textId: 'harmonic_minor_scale_solfege',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 7,
    },
    {
      id: 202506,
      type: 'skill',
      title: 'Harmonic Minor Scale Hand Signs',
      textId: 'harmonic_minor_scale_hand_signs',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 5,
    },
    {
      id: 202520,
      type: 'skill',
      title: {
        scaleDegrees: 'Label scale degrees (harmonic minor)',
        solfegeDoMinor: 'Label solfege (harmonic minor)',
        solfegeLaMinor: 'Label solfege (harmonic minor)',
      },
      textId: 'labeling_solfege_harmonic_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 15,
    },
    {
      id: 202521,
      type: 'skill',
      title: {
        scaleDegrees: 'Write scale degrees on the staff (harmonic minor)',
        solfegeDoMinor: 'Write solfege on the staff (harmonic minor)',
        solfegeLaMinor: 'Write solfege on the staff (harmonic minor)',
      },
      textId: 'writing_solfege_harmonic_minor',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 15,
    },
    {
      id: 202507,
      type: 'skill',
      title: 'Melodic Minor Scale Solfege',
      textId: 'melodic_minor_scale_solfege',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 7,
    },
    {
      id: 202508,
      type: 'skill',
      title: 'Melodic Minor Scale Hand Signs',
      textId: 'melodic_minor_scale_hand_signs',
      master: { accuracy: 95, speed: undefined },
      advance: { accuracy: 60, speed: undefined },
      minQuestions: 5,
    },
    {
      id: 202522,
      type: 'skill',
      title: {
        scaleDegrees: 'Label scale degrees (all minors)',
        solfegeDoMinor: 'Label solfege (all minors)',
        solfegeLaMinor: 'Label solfege (all minors)',
      },
      textId: 'labeling_solfege_all_minors',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 15,
    },
    {
      id: 202523,
      type: 'skill',
      title: {
        scaleDegrees: 'Write scale degrees on the staff (all minors)',
        solfegeDoMinor: 'Write solfege on the staff (all minors)',
        solfegeLaMinor: 'Write solfege on the staff (all minors)',
      },
      textId: 'writing_solfege_all_minors',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 15,
    },
    {
      id: 2029,
      type: 'skill',
      title: 'Recognizing Tonic',
      textId: 'tonic_or_not',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2030,
      type: 'skill',
      title: {
        scaleDegrees: '123 in Major',
        solfegeDoMinor: 'Do Re Mi in Major',
        solfegeLaMinor: 'Do Re Mi in Major',
      },
      textId: 'sd_123_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2031,
      type: 'skill',
      title: {
        scaleDegrees: '123 in  minor',
        solfegeDoMinor: 'Do Re Me in minor',
        solfegeLaMinor: 'La Ti Do in minor',
      },
      textId: 'sd_123_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2032,
      type: 'skill',
      title: {
        scaleDegrees: '1-5 in Major',
        solfegeDoMinor: 'Do-Sol in Major',
        solfegeLaMinor: 'Do-Sol in Major',
      },
      textId: 'sd_12345_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2033,
      type: 'skill',
      title: {
        scaleDegrees: '1-5 in minor',
        solfegeDoMinor: 'Do-Sol in minor',
        solfegeLaMinor: 'La-Mi in minor',
      },
      textId: 'sd_12345_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2034,
      type: 'skill',
      title: {
        scaleDegrees: '5-8 in Major',
        solfegeDoMinor: 'Sol-Do in Major',
        solfegeLaMinor: 'Sol-Do in Major',
      },
      textId: 'sd_5678_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2035,
      type: 'skill',
      title: {
        scaleDegrees: '5-8 in harmonic minor',
        solfegeDoMinor: 'Sol-Do in harmonic minor',
        solfegeLaMinor: 'Mi-La in harmonic minor',
      },
      textId: 'sd_5678_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2036,
      type: 'skill',
      title: {
        scaleDegrees: '5-8 in all minors',
        solfegeDoMinor: 'Sol-Do in all minors',
        solfegeLaMinor: 'Mi-La in all minors',
      },
      textId: 'sd_5678_all_minors',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2037,
      type: 'skill',
      title: 'All notes Major',
      textId: 'sd_1_to_8_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2038,
      type: 'skill',
      title: 'All notes minor',
      textId: 'sd_1_to_8_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2039,
      type: 'skill',
      title: 'All notes octave leaps Major',
      textId: 'sd_1_to_8_major_w_octaves',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 20395,
      type: 'skill',
      title: 'All notes octave leaps minor',
      textId: 'sd_1_to_8_minor_w_octaves',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 20396,
      type: 'skill',
      title: 'Stepwise motion in Major',
      textId: 'sd_frag_stepwise_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 20397,
      type: 'skill',
      title: 'Stepwise motion in minor',
      textId: 'sd_frag_stepwise_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2040,
      type: 'skill',
      title: {
        scaleDegrees: '123 in Major',
        solfegeDoMinor: 'Do Re Mi in Major',
        solfegeLaMinor: 'Do Re Mi in Major',
      },
      textId: 'sd_frag_123_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2041,
      type: 'skill',
      title: {
        scaleDegrees: '123 in  minor',
        solfegeDoMinor: 'Do Re Me in minor',
        solfegeLaMinor: 'La Ti Do in minor',
      },
      textId: 'sd_frag_123_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2042,
      type: 'skill',
      title: {
        scaleDegrees: '1-5 in Major',
        solfegeDoMinor: 'Do-Sol in Major',
        solfegeLaMinor: 'Do-Sol in Major',
      },
      textId: 'sd_frag_12345_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2043,
      type: 'skill',
      title: {
        scaleDegrees: '1-5 in minor',
        solfegeDoMinor: 'Do-Sol in minor',
        solfegeLaMinor: 'La-Mi in minor',
      },
      textId: 'sd_frag_12345_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2044,
      type: 'skill',
      title: {
        scaleDegrees: '5-8 in Major',
        solfegeDoMinor: 'Sol-Do in Major',
        solfegeLaMinor: 'Sol-Do in Major',
      },
      textId: 'sd_frag_5678_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2045,
      type: 'skill',
      title: {
        scaleDegrees: '5-8 in harmonic minor',
        solfegeDoMinor: 'Sol-Do in harmonic minor',
        solfegeLaMinor: 'Mi-La in harmonic minor',
      },
      textId: 'sd_frag_5678_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2046,
      type: 'skill',
      title: {
        scaleDegrees: '5-8 in all minors',
        solfegeDoMinor: 'Sol-Do in all minors',
        solfegeLaMinor: 'Mi-La in all minors',
      },
      textId: 'sd_frag_5678_all_minors',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2047,
      type: 'skill',
      title: 'All notes Major',
      textId: 'sd_frag_1_to_8_major',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2048,
      type: 'skill',
      title: 'All notes minor',
      textId: 'sd_frag_1_to_8_minor',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 2049,
      type: 'skill',
      title: 'All notes octave leaps Major',
      textId: 'sd_frag_1_to_8_major_w_octaves',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 20495,
      type: 'skill',
      title: 'All notes octave leaps minor',
      textId: 'sd_frag_1_to_8_minor_w_octaves',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 20,
    },
    {
      id: 20500,
      type: 'skill',
      title: 'Asc m2',
      textId: 'asc_m2_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20501,
      type: 'skill',
      title: 'Asc M2',
      textId: 'asc_M2_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20502,
      type: 'skill',
      title: 'Asc m3',
      textId: 'asc_m3_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20503,
      type: 'skill',
      title: 'Asc M3',
      textId: 'asc_M3_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20504,
      type: 'skill',
      title: 'Asc P4',
      textId: 'asc_P4_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20505,
      type: 'skill',
      title: 'Asc TT',
      textId: 'asc_TT_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20506,
      type: 'skill',
      title: 'Asc P5',
      textId: 'asc_P5_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20507,
      type: 'skill',
      title: 'Asc m6',
      textId: 'asc_m6_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20508,
      type: 'skill',
      title: 'Asc M6',
      textId: 'asc_M6_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20509,
      type: 'skill',
      title: 'Asc m7',
      textId: 'asc_m7_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20510,
      type: 'skill',
      title: 'Asc M7',
      textId: 'asc_M7_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20511,
      type: 'skill',
      title: 'Asc P8',
      textId: 'asc_P8_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20512,
      type: 'skill',
      title: 'Asc Compound Int.',
      textId: 'asc_compound_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 8,
    },
    {
      id: 20513,
      type: 'skill',
      title: 'Dsc m2',
      textId: 'dsc_m2_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20514,
      type: 'skill',
      title: 'Dsc M2',
      textId: 'dsc_M2_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20515,
      type: 'skill',
      title: 'Dsc m3',
      textId: 'dsc_m3_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20516,
      type: 'skill',
      title: 'Dsc M3',
      textId: 'dsc_M3_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20517,
      type: 'skill',
      title: 'Dsc P4',
      textId: 'dsc_P4_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20518,
      type: 'skill',
      title: 'Dsc TT',
      textId: 'dsc_TT_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20519,
      type: 'skill',
      title: 'Dsc P5',
      textId: 'dsc_P5_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20520,
      type: 'skill',
      title: 'Dsc m6',
      textId: 'dsc_m6_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20521,
      type: 'skill',
      title: 'Dsc M6',
      textId: 'dsc_M6_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20522,
      type: 'skill',
      title: 'Dsc m7',
      textId: 'dsc_m7_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20523,
      type: 'skill',
      title: 'Dsc M7',
      textId: 'dsc_M7_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20524,
      type: 'skill',
      title: 'Dsc P8',
      textId: 'dsc_P8_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20525,
      type: 'skill',
      title: 'Dsc Compound Int.',
      textId: 'dsc_compound_et',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 8,
    },
    {
      id: 20600,
      type: 'skill',
      title: 'm2',
      textId: 'm2_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20601,
      type: 'skill',
      title: 'M2',
      textId: 'M2_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20602,
      type: 'skill',
      title: 'm3',
      textId: 'm3_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20603,
      type: 'skill',
      title: 'M3',
      textId: 'M3_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20604,
      type: 'skill',
      title: 'P4',
      textId: 'P4_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20605,
      type: 'skill',
      title: 'TT',
      textId: 'TT_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20606,
      type: 'skill',
      title: 'P5',
      textId: 'P5_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20607,
      type: 'skill',
      title: 'm6',
      textId: 'm6_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20608,
      type: 'skill',
      title: 'M6',
      textId: 'M6_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20609,
      type: 'skill',
      title: 'm7',
      textId: 'm7_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20610,
      type: 'skill',
      title: 'M7',
      textId: 'M7_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20611,
      type: 'skill',
      title: 'P8',
      textId: 'P8_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 5,
    },
    {
      id: 20612,
      type: 'skill',
      title: 'Compound Int.',
      textId: 'compound_harmonic',
      master: { accuracy: 95, speed: 3 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 15,
    },
    {
      id: 2070,
      type: 'skill',
      title: 'Maj triad ET',
      textId: 'major_triad_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2071,
      type: 'skill',
      title: 'min triad ET',
      textId: 'minor_triad_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2072,
      type: 'skill',
      title: 'Aug triad ET',
      textId: 'augmented_triad_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2073,
      type: 'skill',
      title: 'dim triad ET',
      textId: 'diminshed_triad_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2074,
      type: 'skill',
      title: 'Arpeggiated Triad ET',
      textId: 'arp_triad_et',
      master: { accuracy: 95, speed: 8 },
      advance: { accuracy: 60, speed: 20 },
      minQuestions: 5,
    },
    {
      id: 2075,
      type: 'skill',
      title: 'Close Spacing Triad ET',
      textId: 'close_triad_et',
      master: { accuracy: 95, speed: 8 },
      advance: { accuracy: 60, speed: 20 },
      minQuestions: 7,
    },
    {
      id: 2076,
      type: 'skill',
      title: 'Open Spacing Triad ET',
      textId: 'open_triad_et',
      master: { accuracy: 95, speed: 8 },
      advance: { accuracy: 60, speed: 20 },
      minQuestions: 7,
    },
    {
      id: 2077,
      type: 'skill',
      title: 'Inversions',
      textId: 'triad_inversions_et',
      master: { accuracy: 95, speed: 8 },
      advance: { accuracy: 60, speed: 20 },
      minQuestions: 12,
    },
    {
      id: 2078,
      type: 'skill',
      title: 'Triad Soprano Factor',
      textId: 'triad_soprano_factor_et',
      master: { accuracy: 95, speed: 8 },
      advance: { accuracy: 60, speed: 20 },
      minQuestions: 10,
    },
    {
      id: 2079,
      type: 'skill',
      title: 'Maj7 Chord',
      textId: 'maj_7_chords_et',
      master: { accuracy: 95, speed: 8 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2080,
      type: 'skill',
      title: 'min7 Chord',
      textId: 'min_7_chords_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2081,
      type: 'skill',
      title: 'Dom7 Chord',
      textId: 'dom_7_chords_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2082,
      type: 'skill',
      title: 'dim7 Chord',
      textId: 'dim_7_chords_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2083,
      type: 'skill',
      title: 'half-dim7 Chord',
      textId: 'half_dim_7_chords_et',
      master: { accuracy: 95, speed: 5 },
      advance: { accuracy: 60, speed: 15 },
      minQuestions: 7,
    },
    {
      id: 2084,
      type: 'skill',
      title: 'Arpeggiated 7th Chord ET',
      textId: 'arp_7_chords_et',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 5,
    },
    {
      id: 2085,
      type: 'skill',
      title: 'Close Spacing 7th Chord ET',
      textId: 'close_7_chords_et',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 7,
    },
    {
      id: 2086,
      type: 'skill',
      title: 'Open Spacing 7th Chord ET',
      textId: 'open_7_chords_et',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 7,
    },
    {
      id: 2087,
      type: 'skill',
      title: '7th Chord Inversions',
      textId: 'seventh_chord_inversions_et',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 10,
    },
    {
      id: 2088,
      type: 'skill',
      title: '7th Chord Sop Fractor',
      textId: 'seventh_chord_soprano_factor',
      master: { accuracy: 95, speed: 10 },
      advance: { accuracy: 60, speed: 25 },
      minQuestions: 10,
    },

    // rhythm skills
    {
      id: 1001,
      type: 'skill',
      textId: 'basic_rhythm_math',
      title: 'Basic rhythm math',
      advance: { accuracy: 85 },
      master: { accuracy: 95 },
      minQuestions: 8,
    },
    {
      id: 1002,
      type: 'skill',
      textId: 'dotted_rhythm_math',
      title: 'Dotted rhythm math',
      advance: { accuracy: 85 },
      master: { accuracy: 95 },
      minQuestions: 8,
    },
    {
      id: 1003,
      type: 'skill',
      textId: 'simple_vs_compound',
      title: 'Simple vs Compound',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 8,
    },
    {
      id: 1004,
      type: 'skill',
      textId: 'dup_trip_quad',
      title: 'Duple vs. Triple vs. Quad',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 8,
    },
    {
      id: 1005,
      type: 'skill',
      textId: 'meter_beat_value',
      title: 'Beat Values',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 8,
    },
    {
      id: 1006,
      type: 'skill',
      textId: 'meter_id_simple',
      title: 'Simple Meter ID',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 6,
    },
    {
      id: 1007,
      type: 'skill',
      textId: 'meter_id_compound',
      title: 'Compound Meter ID',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 6,
    },
    {
      id: 1008,
      type: 'skill',
      textId: 'meter_completion_simple',
      title: 'Complete Simple Measures',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 1009,
      type: 'skill',
      textId: 'meter_completion_compound',
      title: 'Complete Compound Measures',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 1010,
      type: 'skill',
      textId: 'meter_completion_cut_time',
      title: 'Complete Cut Time Measures',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 10011,
      type: 'skill',
      textId: 'count_labeling_simple',
      title: 'Label Simple Time Counts',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 10012,
      type: 'skill',
      textId: 'count_labeling_simple_small_subdivisions',
      title: 'Label Simple Time Small Subdiv Counts',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 10013,
      type: 'skill',
      textId: 'count_labeling_compound',
      title: 'Label Compound Time Counts',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 10014,
      type: 'skill',
      textId: 'count_labeling_compound_small_subdivisions',
      title: 'Label Compound Time Small Subdiv Counts',
      advance: { accuracy: 70 },
      master: { accuracy: 95 },
      minQuestions: 5,
    },
    {
      id: 1011,
      type: 'skill',
      textId: 'S:q',
      title: 'S:q',
      svgTitle: '',
      advance: { accuracy: 72 },
      master: { accuracy: 95 },
      minQuestions: 15,
      rhythm: 'q',
      simple: true,
      cutTime: false,
    },
    {
      id: 1013,
      type: 'skill',
      textId: 'S:qr',
      title: 'S:qr',
      svgTitle: '',
      advance: { accuracy: 72 },
      master: { accuracy: 95 },
      minQuestions: 15,
      rhythm: 'qr',
      simple: true,
      cutTime: false,
    },
    {
      id: 1012,
      type: 'skill',
      textId: 'S:ee',
      title: 'S:ee',
      svgTitle: '',
      advance: { accuracy: 72 },
      master: { accuracy: 95 },
      minQuestions: 15,
      rhythm: 'ee',
      simple: true,
      cutTime: false,
    },
    {
      id: 1014,
      type: 'skill',
      textId: 'S:h',
      title: 'S:h',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'h',
      simple: true,
      cutTime: false,
    },
    {
      id: 1015,
      type: 'skill',
      textId: 'S:hr',
      title: 'S:hr',
      svgTitle: 'Half rest',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'hr',
      simple: true,
      cutTime: false,
    },
    {
      id: 1016,
      type: 'skill',
      textId: 'S:w',
      title: 'S:w',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'w',
      simple: true,
      cutTime: false,
    },
    {
      id: 1017,
      type: 'skill',
      textId: 'S:wr',
      title: 'S:wr',
      svgTitle: 'Whole rest',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'wr',
      simple: true,
      cutTime: false,
    },
    {
      id: 1021,
      type: 'skill',
      textId: 'C:q.',
      title: 'C:q.',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'q.',
      simple: false,
      cutTime: false,
    },
    {
      id: 1022,
      type: 'skill',
      textId: 'C:eee',
      title: 'C:eee',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'eee',
      simple: false,
      cutTime: false,
    },
    {
      id: 1023,
      type: 'skill',
      textId: 'C:q.r',
      title: 'C:q.r',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'q.r',
      simple: false,
      cutTime: false,
    },
    {
      id: 1024,
      type: 'skill',
      textId: 'C:h.',
      title: 'C:h.',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'h.',
      simple: false,
      cutTime: false,
    },
    {
      id: 1025,
      type: 'skill',
      textId: 'C:h.r',
      title: 'C:h.r',
      svgTitle: 'Dotted half rest',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'h.r',
      simple: false,
      cutTime: false,
    },
    {
      id: 1026,
      type: 'skill',
      textId: 'C:w.',
      title: 'C:w.',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'w.',
      simple: false,
      cutTime: false,
    },
    {
      id: 1027,
      type: 'skill',
      textId: 'C:w.r',
      title: 'C:w.r',
      svgTitle: 'Dotted whole rest',
      advance: { accuracy: 61.6 },
      master: { accuracy: 95 },
      minQuestions: 2,
      rhythm: 'w.r',
      simple: false,
      cutTime: false,
    },
    {
      id: 1031,
      type: 'skill',
      textId: 'S:ere',
      title: 'S:ere',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'ere',
      simple: true,
      cutTime: false,
    },
    {
      id: 1032,
      type: 'skill',
      textId: 'S:eer',
      title: 'S:eer',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'eer',
      simple: true,
      cutTime: false,
    },
    {
      id: 1033,
      type: 'skill',
      textId: 'S:h.',
      title: 'S:h.',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'h.',
      simple: true,
      cutTime: false,
    },
    {
      id: 1034,
      type: 'skill',
      textId: 'S:q.e',
      title: 'S:q.e',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'q.e',
      simple: true,
      cutTime: false,
    },
    {
      id: 1035,
      type: 'skill',
      textId: 'S:q.re',
      title: 'S:q.re',
      svgTitle: '',
      advance: { accuracy: 64 },
      master: { accuracy: 95 },
      minQuestions: 5,
      rhythm: 'q.re',
      simple: true,
      cutTime: false,
    },
    {
      id: 1036,
      type: 'skill',
      textId: 'S:h.r',
      title: 'S:h.r',
      svgTitle: 'Dotted half rest',
      advance: { accuracy: 76 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'h.r',
      simple: true,
      cutTime: false,
    },
    {
      id: 1041,
      type: 'skill',
      textId: 'C:qe',
      title: 'C:qe',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'qe',
      simple: false,
      cutTime: false,
    },
    {
      id: 1042,
      type: 'skill',
      textId: 'C:qre',
      title: 'C:qre',
      svgTitle: '',
      advance: { accuracy: 64 },
      master: { accuracy: 95 },
      minQuestions: 5,
      rhythm: 'qre',
      simple: false,
      cutTime: false,
    },
    {
      id: 1043,
      type: 'skill',
      textId: 'C:eq',
      title: 'C:eq',
      svgTitle: '',
      advance: { accuracy: 65.6 },
      master: { accuracy: 95 },
      minQuestions: 7,
      rhythm: 'eq',
      simple: false,
      cutTime: false,
    },
    {
      id: 1044,
      type: 'skill',
      textId: 'C:eeer',
      title: 'C:eeer',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'eeer',
      simple: false,
      cutTime: false,
    },
    {
      id: 1045,
      type: 'skill',
      textId: 'C:eqr',
      title: 'C:eqr',
      svgTitle: '',
      advance: { accuracy: 64 },
      master: { accuracy: 95 },
      minQuestions: 5,
      rhythm: 'eqr',
      simple: false,
      cutTime: false,
    },
    {
      id: 1046,
      type: 'skill',
      textId: 'C:qer',
      title: 'C:qer',
      svgTitle: '',
      advance: { accuracy: 64 },
      master: { accuracy: 95 },
      minQuestions: 5,
      rhythm: 'qer',
      simple: false,
      cutTime: false,
    },
    {
      id: 1047,
      type: 'skill',
      textId: 'C:eree',
      title: 'C:eree',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'eree',
      simple: false,
      cutTime: false,
    },
    {
      id: 1048,
      type: 'skill',
      textId: 'C:erq',
      title: 'C:erq',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'erq',
      simple: false,
      cutTime: false,
    },
    {
      id: 1049,
      type: 'skill',
      textId: 'C:eere',
      title: 'C:eere',
      svgTitle: '',
      advance: { accuracy: 64 },
      master: { accuracy: 95 },
      minQuestions: 5,
      rhythm: 'eere',
      simple: false,
      cutTime: false,
    },
    {
      id: 1050,
      type: 'skill',
      textId: 'C:ereer',
      title: 'C:ereer',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ereer',
      simple: false,
      cutTime: false,
    },
    {
      id: 1051,
      type: 'skill',
      textId: 'H:h',
      title: 'H:h',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'h',
      simple: true,
      cutTime: true,
    },
    {
      id: 1052,
      type: 'skill',
      textId: 'H:qq',
      title: 'H:qq',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'qq',
      simple: true,
      cutTime: true,
    },
    {
      id: 1053,
      type: 'skill',
      textId: 'H:hr',
      title: 'H:hr',
      svgTitle: 'Half rest',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'hr',
      simple: true,
      cutTime: true,
    },
    {
      id: 1054,
      type: 'skill',
      textId: 'H:w',
      title: 'H:w',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'w',
      simple: true,
      cutTime: true,
    },
    {
      id: 1055,
      type: 'skill',
      textId: 'H:wr',
      title: 'H:wr',
      svgTitle: 'Whole rest',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'wr',
      simple: true,
      cutTime: true,
    },
    {
      id: 1056,
      type: 'skill',
      textId: 'H:qrq',
      title: 'H:qrq',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'qrq',
      simple: true,
      cutTime: true,
    },
    {
      id: 1057,
      type: 'skill',
      textId: 'H:qqr',
      title: 'H:qqr',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'qqr',
      simple: true,
      cutTime: true,
    },
    {
      id: 1058,
      type: 'skill',
      textId: 'H:h.q',
      title: 'H:h.q',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'h.q',
      simple: true,
      cutTime: true,
    },
    {
      id: 1059,
      type: 'skill',
      textId: 'H:h.rq',
      title: 'H:h.rq',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'h.rq',
      simple: true,
      cutTime: true,
    },
    {
      id: 1060,
      type: 'skill',
      textId: 'S:eqe',
      title: 'S:eqe',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'eqe',
      simple: true,
      cutTime: false,
    },
    {
      id: 1061,
      type: 'skill',
      textId: 'S:eq.',
      title: 'S:eq.',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'eq.',
      simple: true,
      cutTime: false,
    },
    {
      id: 1062,
      type: 'skill',
      textId: 'S:eq.r',
      title: 'S:eq.r',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'eq.r',
      simple: true,
      cutTime: false,
    },
    {
      id: 1063,
      type: 'skill',
      textId: 'S:eqqe',
      title: 'S:eqqe',
      svgTitle: '',
      advance: { accuracy: 64 },
      master: { accuracy: 95 },
      minQuestions: 5,
      rhythm: 'eqqe',
      simple: true,
      cutTime: false,
    },
    {
      id: 1064,
      type: 'skill',
      textId: 'S:erqqe',
      title: 'S:erqqe',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'erqqe',
      simple: true,
      cutTime: false,
    },
    {
      id: 1065,
      type: 'skill',
      textId: 'S:eqqqe',
      title: 'S:eqqqe',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'eqqqe',
      simple: true,
      cutTime: false,
    },
    {
      id: 1066,
      type: 'skill',
      textId: 'S:erqqqe',
      title: 'S:erqqqe',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'erqqqe',
      simple: true,
      cutTime: false,
    },
    {
      id: 1070,
      type: 'skill',
      textId: 'S:ssss',
      title: 'S:ssss',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'ssss',
      simple: true,
      cutTime: false,
    },
    {
      id: 1071,
      type: 'skill',
      textId: 'S:sse',
      title: 'S:sse',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'sse',
      simple: true,
      cutTime: false,
    },
    {
      id: 1072,
      type: 'skill',
      textId: 'S:sser',
      title: 'S:sser',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'sser',
      simple: true,
      cutTime: false,
    },
    {
      id: 1073,
      type: 'skill',
      textId: 'S:ess',
      title: 'S:ess',
      svgTitle: '',
      advance: { accuracy: 69.6 },
      master: { accuracy: 95 },
      minQuestions: 12,
      rhythm: 'ess',
      simple: true,
      cutTime: false,
    },
    {
      id: 1074,
      type: 'skill',
      textId: 'S:erss',
      title: 'S:erss',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'erss',
      simple: true,
      cutTime: false,
    },
    {
      id: 1075,
      type: 'skill',
      textId: 'S:srsss',
      title: 'S:srsss',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'srsss',
      simple: true,
      cutTime: false,
    },
    {
      id: 1076,
      type: 'skill',
      textId: 'S:ssssr',
      title: 'S:ssssr',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ssssr',
      simple: true,
      cutTime: false,
    },
    {
      id: 1077,
      type: 'skill',
      textId: 'C:ssssss',
      title: 'C:ssssss',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'ssssss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1078,
      type: 'skill',
      textId: 'C:ssee',
      title: 'C:ssee',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'ssee',
      simple: false,
      cutTime: false,
    },
    {
      id: 1079,
      type: 'skill',
      textId: 'C:sssse',
      title: 'C:sssse',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'sssse',
      simple: false,
      cutTime: false,
    },
    {
      id: 1080,
      type: 'skill',
      textId: 'C:esse',
      title: 'C:esse',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'esse',
      simple: false,
      cutTime: false,
    },
    {
      id: 1081,
      type: 'skill',
      textId: 'C:essss',
      title: 'C:essss',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'essss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1082,
      type: 'skill',
      textId: 'C:eess',
      title: 'C:eess',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'eess',
      simple: false,
      cutTime: false,
    },
    {
      id: 1083,
      type: 'skill',
      textId: 'C:ssess',
      title: 'C:ssess',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'ssess',
      simple: false,
      cutTime: false,
    },
    {
      id: 1084,
      type: 'skill',
      textId: 'C:qrss',
      title: 'C:qrss',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'qrss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1085,
      type: 'skill',
      textId: 'C:erssss',
      title: 'C:erssss',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'erssss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1086,
      type: 'skill',
      textId: 'C:eress',
      title: 'C:eress',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'eress',
      simple: false,
      cutTime: false,
    },
    {
      id: 1087,
      type: 'skill',
      textId: 'C:ersse',
      title: 'C:ersse',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ersse',
      simple: false,
      cutTime: false,
    },
    {
      id: 1088,
      type: 'skill',
      textId: 'C:qss',
      title: 'C:qss',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'qss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1089,
      type: 'skill',
      textId: 'C:ssq',
      title: 'C:ssq',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ssq',
      simple: false,
      cutTime: false,
    },
    {
      id: 1090,
      type: 'skill',
      textId: 'H:qhq',
      title: 'H:qhq',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'qhq',
      simple: true,
      cutTime: true,
    },
    {
      id: 1091,
      type: 'skill',
      textId: 'H:qh.',
      title: 'H:qh.',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'qh.',
      simple: true,
      cutTime: true,
    },
    {
      id: 1093,
      type: 'skill',
      textId: 'H:eeq',
      title: 'H:eeq',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'eeq',
      simple: true,
      cutTime: true,
    },
    {
      id: 1094,
      type: 'skill',
      textId: 'H:eeqr',
      title: 'H:eeqr',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'eeqr',
      simple: true,
      cutTime: true,
    },
    {
      id: 1095,
      type: 'skill',
      textId: 'H:qee',
      title: 'H:qee',
      svgTitle: '',
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'qee',
      simple: true,
      cutTime: true,
    },
    {
      id: 1096,
      type: 'skill',
      textId: 'H:qree',
      title: 'H:qree',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'qree',
      simple: true,
      cutTime: true,
    },
    {
      id: 1101,
      type: 'skill',
      textId: 'S:ses',
      title: 'S:ses',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'ses',
      simple: true,
      cutTime: false,
    },
    {
      id: 1102,
      type: 'skill',
      textId: 'S:e.s',
      title: 'S:e.s',
      svgTitle: '',
      advance: { accuracy: 68 },
      master: { accuracy: 95 },
      minQuestions: 10,
      rhythm: 'e.s',
      simple: true,
      cutTime: false,
    },
    {
      id: 1103,
      type: 'skill',
      textId: 'S:e.rs',
      title: 'S:e.rs',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'e.rs',
      simple: true,
      cutTime: false,
    },
    {
      id: 1104,
      type: 'skill',
      textId: 'S:se.',
      title: 'S:se.',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'se.',
      simple: true,
      cutTime: false,
    },
    {
      id: 1105,
      type: 'skill',
      textId: 'S:sre.',
      title: 'S:sre.',
      svgTitle: '',
      advance: { accuracy: 61.6 },
      master: { accuracy: 95 },
      minQuestions: 2,
      rhythm: 'sre.',
      simple: true,
      cutTime: false,
    },
    {
      id: 1111,
      type: 'skill',
      textId: 'C:e.se',
      title: 'C:e.se',
      svgTitle: '',
      advance: { accuracy: 66.4 },
      master: { accuracy: 95 },
      minQuestions: 8,
      rhythm: 'e.se',
      simple: false,
      cutTime: false,
    },
    {
      id: 1112,
      type: 'skill',
      textId: 'C:ee.s',
      title: 'C:ee.s',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ee.s',
      simple: false,
      cutTime: false,
    },
    {
      id: 1113,
      type: 'skill',
      textId: 'C:se.e',
      title: 'C:se.e',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'se.e',
      simple: false,
      cutTime: false,
    },
    {
      id: 1114,
      type: 'skill',
      textId: 'C:ere.s',
      title: 'C:ere.s',
      svgTitle: '',
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ere.s',
      simple: false,
      cutTime: false,
    },

    {
      id: 1121,
      type: 'skill',
      textId: 'C:sese',
      title: 'C:sese',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'sese',
      simple: false,
      cutTime: false,
    },
    {
      id: 1124,
      type: 'skill',
      textId: 'C:eses',
      title: 'C:eses',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'eses',
      simple: false,
      cutTime: false,
    },
    {
      id: 11290,
      type: 'skill',
      textId: 'C:ssse.',
      title: 'C:ssse.',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ssse.',
      simple: false,
      cutTime: false,
    },     
    {
      id: 1129,
      type: 'skill',
      textId: 'C:e.sss',
      title: 'C:e.sss',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'e.sss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1127,
      type: 'skill',
      textId: 'C:e.e.',
      title: 'C:e.e.',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 64.8 },
      master: { accuracy: 95 },
      minQuestions: 6,
      rhythm: 'e.e.',
      simple: false,
      cutTime: false,
    },
    {
      id: 1123,
      type: 'skill',
      textId: 'C:e.rsss',
      title: 'C:e.rsss',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'e.rsss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1122,
      type: 'skill',
      textId: 'C:sesss',
      title: 'C:sesss',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'sesss',
      simple: false,
      cutTime: false,
    },
    {
      id: 1125,
      type: 'skill',
      textId: 'C:erses',
      title: 'C:erses',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'erses',
      simple: false,
      cutTime: false,
    },
    {
      id: 1126,
      type: 'skill',
      textId: 'C:ssses',
      title: 'C:ssses',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'ssses',
      simple: false,
      cutTime: false,
    },
    {
      id: 1128,
      type: 'skill',
      textId: 'C:e.re.',
      title: 'C:e.re.',
      svgTitle: '',
      areaId: 2,
      advance: { accuracy: 63.2 },
      master: { accuracy: 95 },
      minQuestions: 4,
      rhythm: 'e.re.',
      simple: false,
      cutTime: false,
    },
  ] as const
).map(
  (node) =>
    Object.freeze(node) as
      | KnowledgeGraphSkillNode
      | KnowledgeGraphTopicNode
      | KnowledgeGraphAreaNode
);

export default knowledgeGraphNodes;

/** Determine if a KnowledgeGraphEdge exists from node with textId `parentId` to node with textId `childId` */
function edgeExists(parentId: string, childId: string): boolean {
  return knowledgeGraphEdges.some((edge) => edge.parentId === parentId && edge.childId === childId);
}

export const skills: Readonly<Readonly<Skill>[]> = knowledgeGraphNodes
  .filter((node: KnowledgeGraphNode): node is KnowledgeGraphSkillNode => node.type === 'skill')
  .map((skillNode: KnowledgeGraphSkillNode): Readonly<Skill> => {
    // Find a topic t with an edge from t => skillNode
    const topic = knowledgeGraphNodes.find(
      (node) => node.type === 'topic' && edgeExists(node.textId, skillNode.textId)
    );

    // Find an area a with an edge from a => skillNode
    const area = knowledgeGraphNodes.find(
      (node) => node.type === 'area' && edgeExists(node.textId, skillNode.textId)
    );

    if (!topic || !area) {
      throw new Error(
        `Couldn't find a topic or couldn't find an area parent for skill node ${skillNode.textId}`
      );
    }

    return Object.freeze({ ...skillNode, topicId: topic.id, areaId: area.id });
  });

export const topics: Readonly<Readonly<Topic>[]> = knowledgeGraphNodes
  .filter((node: KnowledgeGraphNode): node is KnowledgeGraphTopicNode => node.type === 'topic')
  .map((topicNode: KnowledgeGraphTopicNode): Readonly<Topic> => {
    // Find an area a with an edge from a => topicNode
    const area = knowledgeGraphNodes.find(
      (node) => node.type === 'area' && edgeExists(node.textId, topicNode.textId)
    );

    if (!area) {
      throw new Error(`Couldn't find an area parent for topic node ${topicNode.textId}`);
    }

    return Object.freeze({ ...topicNode, areaId: area.id });
  });

export const areas: Readonly<Readonly<Area>[]> = knowledgeGraphNodes
  .filter((node: KnowledgeGraphNode): node is KnowledgeGraphAreaNode => node.type === 'area')
  .map((areaNode: KnowledgeGraphAreaNode): Readonly<Area> => Object.freeze({ ...areaNode }));
